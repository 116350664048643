import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from "./plugins/i18n";
import './plugins/swal'
import './assets/css/styles.scss'
import './assets/css/font.css'
import 'animate.css'
import LoadScript from 'vue-plugin-load-script';
import UUID from "vue-uuid";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import vueFbCustomerChat from "./plugins/vue-fb-customer-chat";
import VModal from 'vue-js-modal';
Vue.use(VModal)
Vue.use(VueAwesomeSwiper);
Vue.use(LoadScript);

Vue.use(require('vue-moment'))

import ZoomOnHover from 'vue-zoom-on-hover'
Vue.use(ZoomOnHover)

Vue.use(UUID);

Vue.config.productionTip = true

new Vue({
  router,
  store,
  i18n,
  vuetify,
  vueFbCustomerChat,
  render: (h) => h(App)
}).$mount('#app')
