import Service from '../../services'

const state = {
  products: [],
  product: {}
}

const getters = {}

const actions = {
  onGetProducts({ commit }) {
    return Service.products.getProducts().then((res) => {
      if (res.status === 200) {
        commit('setProducts', res.data.products)
        return true
      }
    })
  },
  onGetProductById({ commit }, id) {
    return Service.products.getProductById(id).then((res) => {
      if (res.status === 200) {
        commit('setProduct', res.data.product)
        return true
      }
    })
  }
}

const mutations = {
  setProducts(state, data) {
    state.products = data
  },
  setProduct(state, data) {
    state.product = data
  },
  clearProductDetail(state) {
    state.product = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
