import Service from '../../services'

const state ={
    exchangeRate:null,
    THB:{
        currency:'THB',
        description:'THAILAND : BATH (BTH)',
        rate_to_currency: 1,
        raw_buying_transfer: 1,
        raw_mid_rate: 1,
        raw_selling: 1,
        sell_rate: 1,
        isActive: true
    },
    current:{
        currency:'THB',
        description:'THAILAND : BATH (BTH)',
        rate_to_currency: 1,
        raw_buying_transfer: 1,
        raw_mid_rate: 1,
        raw_selling: 1,
        sell_rate: 1,
    }
}

const getters={}
const actions={
    getExchangeRate({commit}){
        return Service.currency.get().then((res)=>{
            if(res.status === 200){
                commit('setExchangeRate', res.data)
            }
        })
    }
}
const mutations={
    setCurrency(state,data){
        for (const property in state.exchangeRate) {
            if(property === data.currency){

                state.exchangeRate[property].isActive = true
            }
            else{
                state.exchangeRate[property].isActive = false
            }
        }
        state.current = data
    },
    setExchangeRate(state,data){
        state.exchangeRate = data;
        state.exchangeRate.THB = state.THB;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}