import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: () => import('../views/ProductDetail')
  },
  {
    path: '/my-shopping-cart',
    name: 'Cart',
    component: () => import('../views/Cart')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/CheckoutStep')
  },
  {
    path: '/my-orders',
    name: 'MyOrders',
    component: () => import('../views/MyOrders')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('../views/ShoppingLive/ShoppingLive')
  },
  {
    path: '/model-category',
    name: 'MainProduct',
    component: () => import('../views/MainItem')
  },
  {
    path: '/part-category/:brand/:cc?/:year?/:model?/:type?/:part?',
    name: 'PartCatalogue',
    component: () => import('../views/PartCatalogue')
  },
  {
    path: '/account-setting/',
    name: 'AccountSetting',
    component: () => import('../views/AccountSetting')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy')
  },
  {
    path: '/terms-of-service',
    name: 'TermOfService',
    component: () => import('../views/TermsOfService')
  },
  {
    path: '/payment-result',
    name: 'PaymentResult',
    component: () => import('../views/PaymentResult')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    props: (route) => ({ query: route.query.q }),
    component: () => import('../views/ResetPassword')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs')
  },
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
