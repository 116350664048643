import Pusher from 'pusher-js'
import {pusher_key,pusher_cluster} from '../../config'

const broadcast = {
    initBroadcast() {
        const client = new Pusher(pusher_key ,{
            cluster:pusher_cluster
        })
        return client;
    },
}
export default broadcast