<template>
  <v-dialog
    :value="value"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '500'"
    persistent
  >
    <v-card class="pa-5" >
      <v-btn x-small right absolute fab elevation="0" @click="eventClose">
        <v-icon>close</v-icon>
      </v-btn>
      <div class="px-10">
      <Signin
        :error="errorMessage"
        @onLogin="login"
        @checkoutAsGuest="checkoutAsGuest"
        @onSignupSuccess="register"
        @setPassword="setPassword"
        @setFirstname="setFirstname"
        @setLastname="setLastname"
        @setEmail="setEmail"
        :only-facebook-login="false"
        :signupDetail="form"
      ></Signin>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Signin from '@/views/StepPages/Signin'
import Service from '../services'
import { mapState } from 'vuex'
export default {
  name: 'LoginDialog',
  data() {
    return {
      form: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        'g-recaptcha-response': '' // recaptcha value
      },
      errorMessage: null
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.Auth.userDetail,
      tempProfile: (state) => state.Auth.tempUserDetail,
      accessToken: (state) => state.Auth.userDetail.access_token
    })
  },
  components: {
    Signin
  },
  props: {
    value: {
      default: false
    }
  },
  methods: {
    setPassword(data) {
      this.form.password = data
    },
    setFirstname(data) {
      this.form.firstname = data
    },
    setLastname(data) {
      this.form.lastname = data
    },
    setEmail(data) {
      this.form.email = data
    },
    async checkoutAsGuest() {
      if (this.tempProfile.access_token) {
        this.$store.commit('Auth/setUserDetail', this.tempProfile)
      } else {
        await this.$store.dispatch('Auth/logInAsGuest', this.$uuid.v4())
        let data = {
          email: this.profile.email,
          firstname: this.profile.firstname,
          lastname: this.profile.lastname,
        }
        await this.register(data, true)
      }
      await this.eventClose()
    },
    login(data) {
      data.is_admin = false;
      Service.auth.login(data).then((res) => {
        if (res.status == 200) {
          this.$store.commit('Auth/setUserDetail', res.data)
          this.errorMessage = ''
          this.eventClose()
        } else if (res.status == 401) {
          this.errorMessage = res.data.message
        }
      })
    },
    async register(data, isGuest = false) {
      data.is_guest =isGuest;
      await Service.auth.register(data).then((res) => {
        if (res.status == 200) {
          if (isGuest) {
            this.$store.commit('Auth/setTempUserDetail', res.data)
          }
          this.$store.commit('Auth/setUserDetail', res.data)
          this.errorMessage = ''
          this.eventClose()
        } else if (res.status == 401) {
          this.errorMessage = res.data.message
        }
      })
    },
    eventClose() {
      this.$store.commit('Auth/loginDialogHandle', false)
    }
  }
}
</script>

<style scoped>
</style>
