import {api_domain, shop_id} from '../../config'
import axios from 'axios'

const organization = {
  getPrivacyPolicy () {
    return axios({
      url: api_domain + 'api/policy/' + shop_id,
      method: 'GET'
    }).catch((e) => {
      console.log('Catch error on getPrivacyPolicy\n', e.message)
      return { status: e.response.status }
    })
  },

  getTermsOfService () {
    return axios({
      url: api_domain + 'api/terms/' + shop_id,
      method: 'GET'
    }).catch((e) => {
      console.log('Catch error on getTermsOfService\n', e.message)
      return { status: e.response.status }
    })
  }
}

export default organization
