<template>
  <MainProduct
    :brandModel="allModel"
    @bannerClick="bannerClick"
    v-if="isProduct"
  ></MainProduct>
  <MainPart v-else></MainPart>
</template>

<script>
import { mapState } from 'vuex'
import MainProduct from '../views/MainProduct'
import MainPart from '../views/MainPart'
export default {
  components: {
    MainPart,
    MainProduct
    //FilterPanel
  },
  metaInfo() {
    let title = 'Part And Accessory For Your Bike'
    if (this.$route.query.model) {
      title = `${this.$route.query.model} (${this.$route.query.year})`
    } else if (Object.keys(this.$route.query).length !== 0) {
      title = `${this.$route.query.brand ? this.$route.query.brand : ''} ${
        this.$route.query.cc ? this.$route.query.cc : ''
      } ${this.$route.query.year ? this.$route.query.year : ''}`
    }
    return {
      title: `Thai bike parts`,
      titleTemplate: `${title} | %s`
    }
  },
  computed: {
    ...mapState({
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList,
      allModel: (state) => state.MainModel.allModel
    })
  },
  data() {
    return {
      isProduct: true,
      isLoading: false,
      items: []
    }
  },
  async created() {
    this.isLoading = true
    if (this.$route.query.model) {
      this.isProduct = false
    } else {
      await this.$store.dispatch('MainModel/onFilterModel', {
        brand: this.brandToId(this.$route.query.brand),
        cc: this.ccToId(this.$route.query.cc),
        year: this.$route.query.year
      })
      this.isProduct = true
    }
    this.isLoading = false
  },
  methods: {
    idToBrand(id) {
      return this.brandList.find((x) => x.id === id).name
    },
    idToCC(id) {
      return this.ccList.find((x) => x.id === id).name
    },
    brandToId(name) {
      let obj = this.brandList.find((x) => x.name === name)
      return obj ? obj.id : null
    },
    ccToId(name) {
      let obj = this.ccList.find((x) => x.name === name)
      return obj ? obj.id : null
    },
    bannerClick($event) {
      this.$router.push({
        name: 'MainProduct',
        query: {
          brand: this.idToBrand($event.brand_id),
          cc: this.idToCC($event.cc_id),
          year: $event.year,
          model: $event.name
        }
      })
    }
  }
}
</script>

<style>
</style>