import axios from 'axios'
import { api_domain } from '../config'

const http = new axios.create({
  baseURL: api_domain
})

const ServiceAPI = {
  get(url, options = null) {
    return http.get(url, options).then((res) => res.data)
  },
  post(url, data = null, options = null) {
    return http
      .post(url, data, options)
      .then((res) => res.data)
      .catch((error) => error.response)
  },
  put(url, data = null, options = null) {
    return http.put(url, data, options).then((res) => res.data)
  },
  delete(url, options = null) {
    return http.delete(url, options).then((res) => res.data)
  }
}

export default ServiceAPI
