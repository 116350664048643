import axios from "axios";
import { api_domain } from "@/config";

const payment = {
    handlePayment(data) {
        return axios({
            url: api_domain + 'api/handle-payment',
            data: {
                access_token: data.access_token,
                cart_id: data.cart_id,
                address_id: data.address_id,
                description: data.description
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on handle-payment\n', e.message)
            return { status: e.response.status }
        })
    },
    omiseCharge(data) {
        return axios({
            url: api_domain + 'api/payment/omise-charge',
            data: {
                access_token: data.data.access_token,
                amount: data.amount,
                currency: data.currency,
                token_id: data.token_id,
                user_address_id: data.data.user_address_id,
                cart_id: data.data.cart_id,
                reference_number: data.data.reference_number,
                vat: data.data.vat,
                sell_rate: data.data.sell_rate,
                shipping_price: data.data.shipping_price
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on omise-charge\n', e.message)
            return { status: e.response.status }
        })
    },
    checkOmiseCharge(orderId){
        return axios({
            url: api_domain + 'api/payment/check-omise-charge',
            params:{
                order_id: orderId
            },
            method:'GET'
        }).catch((e) => {
            console.log('Catch error on omise-charge\n', e.message)
            return { status: e.response.status }
        })
    },
    paypalTransaction(data) {
        return axios({
            url: api_domain + 'api/payment/paypal-transaction',
            data: {
                access_token: data.access_token,
                total_price: data.total_price,
                currency: data.currency,
                user_address_id: data.user_address_id,
                cart_id: data.cart_id,
                reference_number: data.reference_number,
                vat: data.vat,
                sell_rate: data.sell_rate,
                shipping_price: data.shipping_price,
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on omise-charge\n', e.message)
            return { status: e.response }
        })
    },
    getVat() {
        return axios({
            url:  api_domain + 'api/get-vat',
            method: 'GET',
        }).catch((e) => {
            console.log('Catch error on get-vat\n', e.message)
            return { status: e.response.status }
        })
    },
    getBankList() {
        return axios({
            url: api_domain + 'api/payment/bank-list',
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on get-bank-list\n', e.message)
            return { status: e.response.status }
        })
    },
    saveTransAction(data) {
        return axios({
            url: api_domain + 'api/payment/transfer',
            data: data,
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on trans-action\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default payment
