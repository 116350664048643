<template>
    <v-carousel
      cycle
      height="315"
      :style="$vuetify.breakpoint.smAndDown ? { '': '210px' } : {}"
      show-arrows-on-hover
      hide-delimiter-background
      v-model="current"
    >
      <v-carousel-item v-for="(item, i) in banners" :key="i">
        <v-img
          :style="
            $vuetify.breakpoint.smAndDown ? { '': '210px' } : {}
          "
          :src="item.image"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <!-- <div class="flex-center-row mt-5">
      <div
        v-for="(item, i) in banners"
        :key="i"
        :class="{ 'carousel-dots-active': i === current }"
        class="carousel-dots mr-2"
        @click="current = i"
      ></div>
    </div> -->
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    banners: {
      default: []
    },
    interval: {
      default: 3000
    },
    height: {
      default: '100%'
    }
  },
  data: () => ({
    current: 0
  })
}
</script>

<style scoped lang="scss">
.carousel-dots {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #c4c4c4;
  cursor: pointer;
}
.carousel-dots-active {
  background: var(--bkk-theme);
}
</style>
