import Service from '../../services'

const state = {
  userAddress: {},
  billingAddress:null,
  useAddressID: null,
  shippingCost: 0,
  countryList:[],
}

const getters = {
  getUserAddress: (state, getters) => {
    return getters.state.userAddress
  }
}

const actions = {
  onSelectAddress({ commit }, data) {
    commit('setUserAddress', data)
    return true
  },
  onGetCountry({ commit }){
    return Service.address.getCountry().then(res => {
      if (res.status === 200) {
        commit('setCountryList', res.data)
      }
    })
  },
  useAddressID({ commit }, data) {
    commit('setUseAddressID', data)
    return true
  },
  onSetBillingAddress({ commit }, data) {
    commit('setBillingAddress', data)
    return true
  },
  onGetShippingCost ({ commit }, data) {
    return Service.address.getShippingCost(data).then(res => {
      if (res.status === 200) {
        commit('setShippingCost', res.data.shipping_price)
      }
    })
  }
}

const mutations = {
  setCountryList(state, data) {
    state.countryList = data
  },
  setBillingAddress(state, data) {
    state.billingAddress = data
  },
  setUseAddressID(state, data) {
    state.useAddressID = data
  },
  setUserAddress(state, data) {
    state.userAddress = data
  },
  clearAddress(state) {
    state.userAddress = {}
  },
  setShippingCost(state, data) {
    state.shippingCost = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
