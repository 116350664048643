<template>
  <v-card
    style="border: 5px solid grey"
    @click="onClick"
    class="mx-auto rounded-lg text-center"
    max-width="400"
    max-height="300"
  >
    <v-img :alt="model.name" contain height="200px" :src="model.image[0]"> </v-img>
      <h2 class="cust-color text-center">
        {{ model.name }}
      </h2>
  </v-card>
</template>

<script>
export default {
  name: 'GroupPartCard',
  props: {
    model: {
      type: Object
    }
  },
  methods: {
    onClick() {
      this.$emit('cardClick', this.model)
    }
  },
  created() {}
}
</script>

<style>
</style>