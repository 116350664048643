<template>
  <div>
    <div v-if="!onlyFacebookLogin">
      <Loading :loading="isLoading"></Loading>
      <!-- Sign up -->
      <div v-if="forgotPasswordSection">
        <forgot-password :open.sync="forgotPasswordSection"></forgot-password>
      </div>
      <div v-else-if="signupSection">
        <div class="text-center mt-5 txt-m-large mb-3 align-item-center">
          REGISTER
        </div>
        <v-alert v-if="error" dense outlined type="error">
          {{ error }}
        </v-alert>
        <div class="pa-5">
          <div class="mb-5">
            <TextField
              placeholder="Email"
              :value="signupDetail.email"
              @onChange="eventSetEmail"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              type="password"
              placeholder="Password"
              :value="signupDetail.password"
              @onChange="eventSetPassword"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              type="password"
              placeholder="Retype Password"
              :value="confirmPassword"
              @onChange="confirmPassword = $event"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              placeholder="First name"
              :value="signupDetail.firstname"
              @onChange="eventSetFirstname"
            ></TextField>
          </div>
          <div class="mb-5">
            <TextField
              placeholder="Last name"
              :value="signupDetail.lastname"
              @onChange="eventSetLastname"
            ></TextField>
          </div>
          <div class="mx-auto">
            <vue-recaptcha
              :sitekey="recaptcha_site_key"
              :loadRecaptchaScript="true"
              @verify="verifyRecaptchaResponse"
            ></vue-recaptcha>
          </div>
          <v-btn @click="onRegister" class="cust-btn-color mt-3" block>
            Register
          </v-btn>
          <div class="my-3">
            <span class="txt-detail"
              >Already have an account ?
              <span
                @click="signupSection = false"
                class="text-decoration-underline pointer red--text"
                >Login</span
              >
            </span>
          </div>
        </div>
      </div>
      <!-- Sign in -->
      <div v-else>
        <div class="text-center mt-5 txt-m-large mb-3 align-item-center">
          LOGIN
        </div>
        <v-alert v-if="error" dense outlined type="error">
          {{ error }}
        </v-alert>
        <div class="mt-5 mb-5">
          <TextField
            type="email"
            placeholder="Email"
            @onChange="signIn.email = $event"
            :value="signIn.email"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <div class="mb-5">
          <TextField
            type="password"
            placeholder="Password"
            @onChange="signIn.password = $event"
            :value="signIn.password"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <div class="text-right mb-3">
          <span
            class="txt-detail pointer text-decoration-underline"
            @click="forgotPasswordHandler()"
            >Forgot your password ?</span
          >
        </div>
        <v-layout :column="$vuetify.breakpoint.smAndDown">
          <v-btn @click="eventLogin" class="cust-btn-color mt-3" block>
            Log in
          </v-btn>
        </v-layout>
        <div class="text-center my-3 txt-detail">or login with facebook</div>

        <v-btn block color="#3B5998" @click="eventLoginFacebook">
          <v-layout justify-center>
            <v-flex shrink>
              <v-img
                width="20px"
                max-height="20px"
                contain
                class="mr-2"
                :src="require('../../assets/img/social/fb-icon.png')"
              ></v-img>
            </v-flex>
            <span class="white--text"> continue with Facebook </span>
          </v-layout>
        </v-btn>
        <div class="my-3">
          <span class="txt-detail"
            >Don’t have an account ?
            <span
              @click="signupSection = true"
              class="text-decoration-underline pointer red--text"
              >Register</span
            >
          </span>
        </div>

        <v-btn
          @click="checkoutAsGuest"
          class="cust-border-btn-color mt-3"
          block
        >
          Check Out as Guest
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-btn block color="#3B5998" @click="eventLoginFacebook">
        <v-layout justify-center>
          <v-flex shrink>
            <v-img
              width="20px"
              max-height="20px"
              contain
              class="mr-2"
              :src="require('../../assets/img/social/fb-icon.png')"
            ></v-img>
          </v-flex>
          <span class="white--text"> LOGIN WITH FACEBOOK </span>
        </v-layout>
      </v-btn>
    </div>
  </div>
</template>

<script>
import TextField from '../../components/input/TextField'
import Loading from '../../components/Loading'
import ForgotPassword from '../../components/ForgotPassword'
import { mapState } from 'vuex'
// recaptcha
import VueRecaptcha from 'vue-recaptcha'
// config recaptcha site key
import { recaptcha_site_key } from '../../config'

export default {
  components: {
    TextField,
    Loading,
    VueRecaptcha, // Recaptcha
    ForgotPassword
  },
  props: {
    signupEmail: {
      default: ''
    },
    signupDetail: {
      default: null
    },
    onlyFacebookLogin: {
      default: false
    },
    error: {
      default: null
    }
  },

  data() {
    return {
      signupSection: false,
      isLoading: false,
      signIn: {
        email: '',
        password: ''
      },
      confirmPassword: '',
      recaptcha_site_key, // recaptcha sitekey import from config
      forgotPasswordSection: false
    }
  },
  computed: {
    ...mapState({
      userDetail: (state) => state.Auth.userDetail
    })
  },
  methods: {
    eventCreateAcc() {
      if (this.signupEmail) {
        this.signupSection = true
      } else {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please correct your email'
        })
      }
    },
    eventLogin() {
      this.$emit('onLogin', this.signIn)
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      return re.test(email)
    },
    eventChangeEmail(input) {
      this.$emit('onChangeSignupEmail', input)
    },
    onRegister() {
      if (!this.validateRegister()) {
        this.$swal.fire({
          ...this.$swalError,
          text:
            'Please correct your information and re-type password to confirm'
        })
        this.confirmPassword = ''
        return
      }
      // register api
      //
      this.$emit('onSignupSuccess', this.signupDetail)
    },
    validateRegister() {
      let pass = true
      if (!this.signupDetail.firstname || !this.signupDetail.lastname) {
        pass = false
      }
      if (this.confirmPassword !== this.signupDetail.password) {
        pass = false
      }
      if (!this.signupDetail['g-recaptcha-response']) {
        pass = false
      }
      return pass
    },
    eventSetPassword(val) {
      this.$emit('setPassword', val)
    },
    eventSetFirstname(val) {
      this.$emit('setFirstname', val)
    },
    eventSetLastname(val) {
      this.$emit('setLastname', val)
    },
    eventSetEmail(val) {
      this.$emit('setEmail', val)
    },
    checkoutAsGuest() {
      this.$emit('checkoutAsGuest')
    },
    async eventLoginFacebook() {
      this.isLoading = true
      let _this = this
      window.FB.login(
        function (res) {
          if (res.authResponse) {
            window.FB.api(
              '/me',
              'get',
              {
                fields:
                  'id,name,email,first_name,last_name,picture.width(150).height(150)'
              },
              function (response) {
                _this.$store
                  .dispatch('Auth/onFbRegister', { auth: res, data: response })
                  .then(() => {
                    _this.$store.dispatch(
                      'Cart/getCart',
                      _this.userDetail.access_token
                    )
                  })
                _this.isLoading = false
                _this.$store.commit('Auth/loginDialogHandle', false)
              }
            )
          } else {
            _this.isLoading = false
            _this.$swal.fire({
              ..._this.$swalError,
              text: 'Login failed.'
            })
          }
        },
        { scope: 'email' }
      )
      this.isLoading = false
    },
    // event function from recaptcha library trigger when challenge recaptcha is verify
    // will return response verify code to for checking to api
    verifyRecaptchaResponse(response) {
      this.signupDetail['g-recaptcha-response'] = response
    },
    forgotPasswordHandler(open = true) {
      this.forgotPasswordSection = open
    }
  }
}
</script>

<style scoped lang="scss">
.layout-border {
  border: solid 2px #c4c4c4;
}
.bd-selectbox {
  display: inline-block;
  &.day {
    width: 70px;
  }
  &.month {
    width: 90px;
  }
  &.year {
    width: 90px;
  }
}
</style>
