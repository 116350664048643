<template>
  <div class="animate__animated animate__fadeInRight pointer">
    <v-hover v-slot="{ hover }">
      <v-card
        style="border: 4px solid #e0e0e0"
        @click="click"
        :class="{ 'on-hover': hover }"
        max-width="344"
        outlined
      >
        <v-list-item three-line>
          <v-row align="center" >
            <v-col cols="6">
              <v-img
                class="mr-1 model-img"
                contain
                :src="model.image[0]"
              >
              </v-img>
            </v-col>
            <v-col cols="6">
              <span class="cust-color">{{ model.name }}</span
              ><br />
              <span class="cust-blue-color">รุ่นปี {{ model.year }}</span>
            </v-col>
          </v-row>

          <!-- <v-list-item-content>
            <v-list-item-title>
             
            </v-list-item-title>
          </v-list-item-content> -->
        </v-list-item>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductItem',
  computed: {
    ...mapState({
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList
    })
  },
  props: {
    model: {
      type: Object
    }
  },
  methods: {
    click() {
      this.$emit('onClick', this.model)
    },
    idToName(id, list) {
      return list ? list.find((x) => x.id === id).name : ''
    }
  }
}
</script>

<style>
.model-img {
  float: left;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
</style>