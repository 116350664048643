<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <div class="text-center mt-5 txt-m-large mb-3 align-item-center">
      Forgot Password
    </div>
    <div class="mt-5 mb-5">
      <TextField
        type="email"
        placeholder="Email"
        @onChange="email = $event"
        @keyup.enter="sendforgotPasswordHandler()"
        :value="email"
      ></TextField>
    </div>
    <v-layout :column="$vuetify.breakpoint.smAndDown">
      <v-btn
        @click="sendforgotPasswordHandler()"
        class="cust-btn-color mt-3"
        block
      >
        Send
      </v-btn>
    </v-layout>
    <v-layout :column="$vuetify.breakpoint.smAndDown">
      <v-btn @click="forgotPasswordHandler(false)" class="mt-3" block>
        Back
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import Loading from '../components/Loading'
// import GroupPartCard from '../components/GroupPartCard'
// import { mapState } from 'vuex'
import Service from '../services'
import TextField from './input/TextField'
export default {
  name: 'ForgotPassword',
  components: {
    Loading,
    TextField
  },
  props: ['open'],
  data() {
    return {
      isLoading: false,
      email: ''
    }
  },
  computed: {
    openComputed: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },
  methods: {
    async sendforgotPasswordHandler() {
      if (!this.email) {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please correct your email'
        })
        return
      }

      this.isLoading = true

      let res
      try {
        res = await Service.auth.requestForgotPassword({
          email: this.email
        })
      } catch (err) {
        this.isLoading = false

        this.$swal.fire({
          ...this.$swalError,
          text: err.message
        })
        return
      }
      this.isLoading = false

      this.$swal.fire({
        ...this.$swalSuccess,
        text: 'Reset Password Link has sent to your email.'
      })

      this.forgotPasswordHandler(false)
    },
    forgotPasswordHandler(open = true) {
      this.openComputed = open
    }
  },
  created() {}
}
</script>

<style>
</style>