import Service from '../../services'
import Cart from './Cart'

const state = {
  fbAuth: {
    status: '',
    authResponse: {
      accessToken: undefined,
      signedRequest: undefined,
      userID: undefined
    }
  },
  userDetail: {
    is_guest: false,
    fb_id: undefined,
    email: undefined,
    phone: undefined,
    firstname: undefined,
    lastname: undefined,
    access_token: undefined,
    fb_access_token: undefined,
    fb_expiresIn: undefined,
    fb_data_access_expiration_time: undefined,
    is_address: undefined
  },
  tempUserDetail: {
    is_guest: false,
    fb_id: undefined,
    email: undefined,
    phone: undefined,
    firstname: undefined,
    lastname: undefined,
    access_token: undefined,
    fb_access_token: undefined,
    fb_expiresIn: undefined,
    fb_data_access_expiration_time: undefined,
    is_address: undefined
  },
  loginDialog: false
}

const getters = {
  getUserDetail: (state, getters) => {
    return state.userDetail
  }
}

const actions = {
  onFbRegister({ commit }, data) {
    var postData = {}
    postData = { ...data.auth, ...data.data }
    return Service.auth.fbRegister(postData).then((res) => {
      if (res.status === 200) {
        commit('setAuth', data.auth)
        commit('setUserDetail', res.data)
      }
    })
  },
  logInAsGuest({commit}, data){
    commit('logInAsGuest', data)
    return true
  },
  logOut({ commit }) {
    commit('clearAuth')
    return true
  },
  saveAddress({ commit }, data) {
    commit('setAddress', data)
    return true
  }
}

const mutations = {
  logInAsGuest(state, data){
    state.userDetail.email = data,
    state.userDetail.firstname = 'Guest user',
    state.userDetail.lastname = 'Guest user',
    state.userDetail.access_token = 'Guest user',
    state.userDetail.is_guest=true
  },
  loginDialogHandle(state, data) {
    state.loginDialog = data
  },
  setAuth(state, data) {
    state.fbAuth = data
  },
  setAddress(state, data) {
    state.userDetail.is_address = data
  },
  setUserDetail(state, data) {
    state.userDetail = data
  },
  setTempUserDetail(state, data) {
    state.tempUserDetail = data
  },
  clearAuth(state) {
    localStorage.removeItem('vuex')
    state.fbAuth = {
      status: '',
      authResponse: {
        accessToken: undefined,
        signedRequest: undefined,
        userID: undefined
      }
    }
    state.userDetail = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
