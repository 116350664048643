import Store from '../../store'
import Service from '../../services'

const state = {
  previewDialog: false,
  dialogTimeout: 2000,
  inCart: [],
  statusCart: {
    step: null
  },
  cartDetail: {
    cart_items: []
  }
}

const getters = {
  getCart: (state, getters) => {
    return state.cartDetail
  }
}

const actions = {
  add({ commit }, param) {
    return Service.carts.increaseOneItemToCart(param).then(res => {
      if (res.status === 200) {
        commit('setCartDetail', res.data)
        commit('setInCart', res.data.cart_item)
      }
    })
  },
  removeItem({ commit }, product) {
    return Service.carts.removeItemFromCart(product).then(res => {
      if (res.status === 200) {
        commit('setCartDetail', res.data)
        commit('setInCart', res.data.cart_item)
      }
    })
  },
  decreaseOne({ commit }, product) {
    return Service.carts.decreaseOneItemToCart(product).then(res => {
      if (res.status === 200) {
        commit('setCartDetail', res.data)
        commit('setInCart', res.data.cart_item)
      }
    })
  },
  popUpDialog({ commit }) {
    commit('popUpDialog')
  },
  dismissDialog({ commit }) {
    commit('dismissDialog')
  },
  saveShipAddress({ commit }, item) {
    commit('saveShipAddress', item)
  },
  getCart({ commit }, token) {
    Service.carts.getCarts(token).then(res => {
      if (res.status === 200) {
        commit('setCartDetail', res.data)
        commit('setInCart', res.data.cart_item)
      }
    })
  }
}

const mutations = {
  add(state, { main_sku, sku, quantity }) {
    let p = Store.state.Products.products.filter(
      (x) => x.main_sku === main_sku
    )[0]
    if (!p) return
    let sameSize, sameProduct
    for (const old of state.inCart) {
      sameProduct = main_sku === old.main_sku
      sameSize = sku === old.size.sku
      if (sameProduct && sameSize) {
        old.quantity += quantity
        return
      }
    }
    let size = p.sku_size_stock_list.filter((x) => x.sku === sku)[0]
    state.inCart.unshift({
      name: p.name,
      photo: p.photos[0].image_url,
      price: parseFloat(p.price),
      currency: p.currency,
      main_sku: p.main_sku,
      full_sku: p.main_sku.toString() + size.sku.toString(),
      size: size,
      quantity: quantity ? parseInt(quantity) : 1
    })
  },
  remove(state, item) {
    state.inCart = state.inCart.filter((x) => x.full_sku !== item.full_sku)
  },
  increaseOne(state, item) {
    state.inCart.forEach((x) => {
      if (x.full_sku === item.full_sku) {
        x.quantity++
      }
    })
  },
  decreaseOne(state, item) {
    state.inCart.forEach((x) => {
      if (x.full_sku === item.full_sku) {
        if (x.quantity > 1) {
          x.quantity--
        }
      }
    })
  },
  popUpDialog(state) {
    state.previewDialog = true
    setTimeout(() => {
      state.previewDialog = false
    }, state.dialogTimeout)
  },
  dismissDialog(state) {
    state.previewDialog = false
  },
  setCartDetail(state, data) {
    state.cartDetail = data
  },
  setInCart(state, data) {
    if(data){
      state.inCart = data
    }
    else{ state.inCart = [] }
   
  },
  saveShipAddress(state, data) {
    state.statusCart = data
  },
  clearShipAddress(state) {
    state.statusCart = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
