import Vue from 'vue'
import VueI18n from "vue-i18n"
import USD from "./lang/en_US"
import THB from "./lang/th_TH"
import KRW from "./lang/kr_KR"
import store from '../store'

Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
    //construction a new VueI18n
    locale: store.state.Currency.current.currency,
    fallbackLocale: "THB", //always displays English if Thai translation is not available
    messages: {
        //'locales' directory contains all the translations in the form of json files
        USD: USD,
        KRW: KRW,
        THB: THB,
    },
});

export default i18n