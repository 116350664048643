<template>
  <div>
    <v-hover class="pointer rounded" v-slot="{ hover }">
      <div :class="[hover ? 'elevation-5' : '','py-2']">
      <v-img
        :alt="item.name"
        width="100%"
        height="150"
        :src="item.image[0]"
        contain
        @click="onClick"
      >
      </v-img>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    onClick() {
      this.$emit('clickBanner', this.item.name)
    }
  }
}
</script>
<style lang="scss" scoped>
.img-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.highlight-txt {
  font-size: 18px;
  color: var(--bkk-txt-color) !important;
  &.add-button {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
  }
}
.v-card:not(.on-hover) {
  opacity: 1;
}
.img-opacity {
  opacity: 0.4;
}
</style>