import { api_domain } from '../../config'
import axios from 'axios'

const products = {
  getProducts() {
    return axios({
      url: api_domain + 'api/get-products',
      method: 'GET'
    }).catch((e) => {
      console.log('Catch error on getProducts\n', e.message)
      return { status: e.response.status }
    })
  },
  getProductById(id) {
    return axios({
      url: api_domain + 'api/get-product/' + id,
      method: 'GET'
    }).catch((e) => {
      console.log('Catch error on getProduct\n', e.message)
      return { status: e.response.status }
    })
  }
}

export default products
