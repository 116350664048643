import { api_domain } from '../../config'
import axios from 'axios'

const mainModel = {
    getSpecificModel(param) {
        return axios({
            url: api_domain + `api/get-model`,
            params:{ 
                brand: param.brand,
                cc: param.cc,
                year: param.year,
                model: param.model,
                type: param.type 
            },
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getSpecificModel\n', e.message)
            return { status: e.response.status }
        })
    },
    getYear() {
        return axios({
            url: api_domain + `api/get-year`,
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getYear\n', e.message)
            return { status: e.response.status }
        })
    },
    getAll() {
        return axios({
            url: api_domain + `api/get-all`,
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getAll\n', e.message)
            return { status: e.response.status }
        })
    },
    filterProp(param){
        return axios({
            url: api_domain + `api/filter-prop`,
            params:{ 
                brand: param.brand,
                cc: param.cc,
                year: param.year
            },
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on filterProp\n', e.message)
            return { status: e.response.status }
        })
    },
    filterModel(param){
        return axios({
            url: api_domain + `api/filter-model`,
            params:{ 
                brand: param.brand,
                cc: param.cc,
                year: param.year
            },
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on filterModel\n', e.message)
            return { status: e.response.status }
        })
    },
    getByBrand(param) {
        return axios({
            url: api_domain + 'api/get-model-by-brand',
            params:{ brand: param.brand_id },
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getByBrand\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default mainModel