import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/brand`
const brand = {
    get(){
        return axios({
            url: url,
            params:{ display: 1 },
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-brand\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default brand