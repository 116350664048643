<template>
  <div class="fill-height">
    <Loading :loading="isLoading"></Loading>
    <v-row class="align-center">
      <v-col :class="youtubeLink ? 'col-md-7 col-12' : 'col-12'">
            <Carousel :banners="bannerList"></Carousel>
      </v-col>
      <v-col v-if="youtubeLink" class="col-md-5 col-12">
        <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/fjPeeJRZmVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 text-center">
        <h2 class="red--text">ALL BRANDS</h2>
        <h1>BROWSE BY BRANDS</h1>
      </v-col>
      <v-col class="px-10" cols="12">
        <swiper class="swiper px-10" :options="swiperOption">
          <swiper-slide v-for="(item, i) in brandList" :key="i">
            <Banner @clickBanner="clickBanner" :item="item"></Banner>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </v-col>

      <v-col class="col-12">
        <v-divider class="elevation-2"></v-divider>
        <div class="mx-15 pa-5 mt-10 rounded-0 elevation-8">
          <FilterPanel
            :brand="this.brandToId(this.$route.query.brand)"
            :cc="this.ccToId(this.$route.query.cc)"
            :selectYear="parseInt(this.$route.query.year)"
          ></FilterPanel>
        </div>
      </v-col>
      <v-col class="col-12">
        <MainItem></MainItem>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Loading from '../components/Loading'
import { mapState } from 'vuex'
import Banner from '../components/Banner'
import FilterPanel from '../components/FilterPanel'
import MainItem from './MainItem'
import Carousel from '../components/Carousel'
export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    Carousel,
    // Slide,
    Loading,
    Banner,
    FilterPanel,
    MainItem
    //Carousel
  },
  data() {
    return {
      atPageBottom: false,
      isLoading: false,
      banners: [],
      categorySelected: { name: 'All' },
      swiperOption: {
        loop: true,
        loopFillGroupWithBlank: true,
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  async created() {
    this.$store.dispatch('Banner/getBanner')
    this.$store.dispatch('Banner/getYoutubeLink')
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.atPageBottom = this.bottomVisible()
    })
  },
  computed: {
    ...mapState({
      allModel: (state) => state.MainModel.allModel,
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList,
      bannerList: (state) => state.Banner.bannerList,
      youtubeLink: (state) => state.Banner.youtubeLink,
    })
  },
  methods: {
    routeTo(id) {
      this.$router.push({ name: 'Home', query: { id: id } })
    },
    clickBanner($event) {
      this.$router.push({ name: 'Home', query: { brand: $event } })
    },
    async quickAdd(stock_id) {
      // only 3 fields required then action in Cart.js
      let product = {
        access_token: this.accessToken,
        product_stock_id: stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', product)
      this.isLoading = false
      this.$store.dispatch('Cart/popUpDialog')
    },
    bottomVisible() {
      // detect bottom func
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + Math.ceil(scrollY) >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    brandToId(name) {
      let obj = this.brandList.find((x) => x.name === name)
      return obj ? obj.id : null
    },
    ccToId(name) {
      let obj = this.ccList.find((x) => x.name === name)
      return obj ? obj.id : null
    }
  }
}
</script>
<style scoped>
.scrolling-wrapper-flexbox {
  display: flex;
  overflow-x: auto;
}
.card-scroll {
  flex: 0 0 auto;
}
</style>
