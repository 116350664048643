import Service from '../../services'
const state = {
    brandModel: [],
    allModel: [],
    currentModel: {},
    route: [],
    yearList: [],
    brandList: [],
    ccList: [],
}
const actions = {
    onGetYear({ commit }) {
        return Service.mainModel.getYear().then((res) => {
            if (res.status === 200) {
                commit('setYearList', res.data)
                return true
            }
        })
    },
    onGetAll({ commit }) {
        return Service.mainModel.getAll().then((res) => {
            if (res.status === 200) {
                commit('setAll', res.data)
                return true
            }
        })
    },
    onGetFilterProp({ commit }, parameter) {
        return Service.mainModel.filterProp(parameter).then((res) => {
            if (res.status === 200) {
                commit('setProp', res.data)
                return true
            }
        })
    },
    onFilterModel({ commit }, parameter) {
        return Service.mainModel.filterModel(parameter).then((res) => {
            if (res.status === 200) {
                commit('setAll', res.data)
                return true
            }
        })
    },
    onGetByBrand({ commit }, parameter) {
        return Service.mainModel.getByBrand(parameter).then((res) => {
            if (res.status === 200) {
                commit('setAll', res.data)
                return true
            }
        })
    },
    onGetSpecificModel({ commit }, parameter) {
        return Service.mainModel.getSpecificModel(parameter).then((res) => {
            if (res.status === 200) {
                commit('setCurrentModel', res.data)
                return true
            }
        })
    }
}
const mutations = {
    setProp(state, data) {
        state.brandList = data.brand;
        state.ccList = data.cc;
        state.yearList = data.year;
    },
    setYearList(state, data) {
        state.yearList = data;
    },
    setMainModel(state, data) {
        state.brandModel = data;
    },
    setCurrentModel(state, data) {
        if (data) {
            data.image = data.image ? JSON.parse(data.image) : data.image;
            data.group_part.forEach(x => {
                x.image = x.image ? JSON.parse(x.image) : x.image;
            });
            state.currentModel = data;
        }
    },
    setAll(state, data) {
        data.forEach(x => {
            x.image = x.image ? JSON.parse(x.image) : x.image;
            x.motor_model.forEach(y => {
                y.image = y.image ? JSON.parse(y.image) : y.image;
            });
        });
        state.allModel = data;
    }
}
const getters = {}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}