import { api_domain } from '../../config'
import axios from 'axios'
const currency = {
    get(){
        return axios({
            url: `${api_domain}api/get-exchange-rate`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-exchange-rate\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default currency