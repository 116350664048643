import { api_domain } from '../../config'
import axios from 'axios'

const masterData = {
    getProvinceKR() {
        return axios({
            url: api_domain + 'api/get-province-kr',
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getProvince\n', e.message)
            return { status: e.response.status }
        })
    },

    getDistrictKR(provinceId) {
        return axios({
            url: api_domain + 'api/get-district-kr/' + provinceId,
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getDistrict\n', e.message)
            return { status: e.response.status }
        })
    },
    getProvince() {
        return axios({
            url: api_domain + 'api/get-province',
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getProvince\n', e.message)
            return { status: e.response.status }
        })
    },

    getDistrict(provinceId) {
        return axios({
            url: api_domain + 'api/get-district/' + provinceId,
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getDistrict\n', e.message)
            return { status: e.response.status }
        })
    },

    getSubDistrict(districtId) {
        return axios({
            url: api_domain + 'api/get-sub-district/' + districtId,
            method: 'GET'
        }).catch((e) => {
            console.log('Catch error on getSubDistrict\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default masterData