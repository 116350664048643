import Service from '../../services'

const state = {
    liveSource:{},
    liveHistory:[],
    productList:[{}],
    bkproductList:[{}]
}
const actions = {
    onGetAllLiveHistory({commit},data) {
        return Service.live.getAllLiveHistory(data).then((res)=>{
            if(res.status === 200){
                commit('setLiveHistory',JSON.parse(JSON.stringify(res.data)));
                console.log('allLive',res)
                return true
            }
        })
    },
    onGetCurrentLive({commit}){
        return Service.live.getCurrentLive().then((res)=>{
            if(res.status === 200){
                if(res.data === "Offline") commit('setLiveSource','');
                else commit('setLiveSource',res.data);
                return true
            }
        })
    },
    onGetAllProductLive({commit},data){
        return Service.live.getAllProductLive(data.liveId,data.token).then((res)=>{
            if(res.status === 200){
                commit('setProductList',res.data);
                commit('setBKProductList',res.data);
                return true;
            }
        })
    },
    filterProductLive({commit,state},keyword){  
            let prodList = []
            state.bkproductList.map(x=>{
                if(x.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
                    prodList.push(x)
                }         
            })
            commit('setProductList',prodList);
    }
}
const mutations = {
    setLiveHistory(state, data){
        state.liveHistory = data
    },
    setLiveSource(state, data){
        state.liveSource = data;
    },
    setProductList(state, data){
        state.productList = data;
    },
    setBKProductList(state, data){
        state.bkproductList = data;
    },

    editProductList(state, sku){
        if(sku){
            let index = state.productList.findIndex(x=>x.id === sku.id)
            if(index != -1){
                state.productList.splice(index, 1); 
                //state.bkproductList.splice(index, 1); 
                state.productList.push(sku);
                //state.bkproductList.push(sku);
            }
            else{
                state.productList.push(sku);
                //state.bkproductList.push(sku);
            }
        }
    }
}
const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
