import auth from './api/auth'
import categories from './api/categories'
import products from './api/products'
import carts from './api/cart'
import address from './api/address'
import organization from './api/organization'
import live from './api/live'
import broadcast from './broadcast/broadcast'
import order from './api/order'
import payment from "@/services/api/payment"
import mainModel from './api/mainModel'
import part from './api/part'
import brand from './api/brand'
import cc from './api/cc'
import currency from './api/currency'
import banner from './api/banner'
import masterData from './api/masterData'

export default {
  auth,
  categories,
  products,
  address,
  carts,
  live,
  organization,
  broadcast,
  order,
  payment,
  mainModel,
  part,
  brand,
  cc,
  currency,
  banner,
  masterData
}
