import Service from '../../services'

const state ={
    bannerList:[],
    youtubeLink:null
}

const getters={}
const actions={
    getBanner({commit}){
        return Service.banner.get().then((res)=>{
            if(res.status === 200){
                commit('setBanner', res.data)
            }
        })
    },
    getYoutubeLink({commit}){
        return Service.banner.getYoutubeLink().then((res)=>{
            if(res.status === 200){
                commit('setYoutubeLink', res.data)
            }
        })
    }
}
const mutations={
    setBanner(state,data){
        state.bannerList = data;
    },
    setYoutubeLink(state, data){
        // const urlParams = new URLSearchParams(data.value);
        // console.log(urlParams);
        // const myParam = urlParams.get("v");
        // console.log(data.value, myParam);
        state.youtubeLink = data.value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}