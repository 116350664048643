// CONSTANTS
export const app_id = '2613438552286562'
export const shop_id = 1
export const part_type = [
  { id: 1, name: 'Parts Manual' },
  { id: 2, name: 'Parts Color' }
]
export const currency_symbol = {'USD':'$' ,'KRW':'₩'  ,'THB':'฿'}
export const language_symbol = {'USD':'ENGLISH' ,'KRW':'한국어'  ,'THB':'ภาษาไทย'}
// Pusher
export const pusher_key = '69a0d04e0e9d4fd1be03'
export const pusher_cluster = 'ap1'

// PRODUCTION API
export const api_domain = 'https://api.thaibikeparts.com/'
// LOCAL API
// export const api_domain = 'http://127.0.0.1:8000/'
// TEST API
// export const api_domain = 'https://apihellocst.ksta.co/'

// Recaptcha site key
export const recaptcha_site_key = '6Lfy6sYaAAAAAPebeVXlUuBwHs4DrIuXZL43MTGT'
// omise public key
export const omise_public_key = 'pkey_5p6pkb9n6i38tzeiux0'
