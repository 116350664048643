<template>
  <v-toolbar height="120px" class="header-tab elevation-5">
    <Loading :loading="isLoading"></Loading>
    <v-toolbar-title>
      <v-img
        :src="require('../assets/img/LogoHello.jpg')"
        alt="Thai_Bike_Part"
        contain
        :width="$vuetify.breakpoint.smAndDown ? '200px' : '250px'"
        class="pointer mt-5"
        @click="routeTo('Home', false)"
      ></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="align-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="text-center" text v-bind="attrs" v-on="on">
            <v-img
              :alt="currency.currency"
              class="mr-1"
              width="20px"
              :src="require(`../assets/img/${currency.currency}.png`)"
            ></v-img>
            <h2 v-if="$vuetify.breakpoint.smAndDown ? false : true">
              {{ languageSymbol[currency.currency] }}
            </h2>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(value, index) in exchangeRate" :key="index">
            <v-list-item-title class="pointer" @click="setCurrency(value)">
              <div class="flex-center-col">
                <v-img
                  :alt="value.currency"
                  class="mr-1"
                  width="20px"
                  :src="require(`../assets/img/${value.currency}.png`)"
                ></v-img>
                <div>{{ languageSymbol[value.currency] }}</div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-center"
            text
            v-bind="attrs"
            v-on="on"
            @click="login(false)"
          >
            <v-icon> mdi-account-outline </v-icon>
            <h2 v-if="$vuetify.breakpoint.smAndDown ? false : true">
              <div v-if="accessToken">
                {{ profile.firstname }}
              </div>
              <div v-else>{{ $t('header.login') }}</div>
            </h2>
          </v-btn>
        </template>
        <v-list v-if="accessToken">
          <v-list-item v-if="!isGuest">
            <v-list-item-title
              @click="routeTo('AccountSetting', true)"
              class="pointer"
            >
              <v-icon> mdi-lock-outline </v-icon>{{ $t('accountSetting.accountSetting') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              @click="routeTo('MyOrders', true)"
              class="pointer"
            >
              <v-icon> mdi-basket-outline </v-icon> {{ $t('header.myPurchase') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isGuest">
            <v-list-item-title @click="onLogout" class="pointer red--text">
              <v-icon class="red--text"> mdi-logout </v-icon> Logout
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isGuest">
            <v-list-item-title @click="login(true)" class="pointer">
              <v-icon> mdi-login </v-icon> {{ $t('header.loginOrRegister') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-badge
        inline
        tile
        :value="accessToken ? !!inCartLength : null"
        :content="accessToken ? inCartLength : null"
      > -->
      <v-btn
        @click="routeTo('Cart', true)"
        class="text-center cust-color align-center"
        text
      >
        <v-icon> mdi-basket-outline </v-icon>
        <h2 v-if="$vuetify.breakpoint.smAndDown ? false : true">
          {{ $t('general.myCart') }}
          <v-avatar
            v-if="accessToken ? inCartLength : null"
            color="#C30505"
            class="rounded"
            tile
            size="30"
          >
            <span class="white--text">{{
              accessToken ? inCartLength : null
            }}</span>
          </v-avatar>
        </h2>
      </v-btn>
      <!-- </v-badge> -->
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import Loading from '../components/Loading'
import { mapState } from 'vuex'
import { language_symbol } from '../config'
import { formatNumber } from '@/plugins/global-funcs'
export default {
  name: 'Header',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      selectedBank: null,
      sidebar: false,
      formatNumber: formatNumber,
      selectedCurrency: null,
      languageSymbol: language_symbol,
      socialIcon: [
        {
          src: require('../assets/img/social/fb.png'),
          link: 'https://www.facebook.com/mybkkshop/'
        },
        // {
        //     src: require('../assets/img/social/phone.png'),
        //     link: ''
        // },
        {
          src: require('../assets/img/social/ig.png'),
          link: 'https://www.instagram.com/mybkkshop/'
        }
      ]
    }
  },
  async created() {
    // if (this.accessToken) {
    //   await this.$store.dispatch('Cart/getCart', this.accessToken)
    // }
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      bankHost: (state) => state.Payment.bankHost,
      inCartLength: (state) =>
        state.Cart.inCart ? state.Cart.inCart.length : null,
      previewDialog: (state) => state.Cart.previewDialog,
      dialogTimeout: (state) => state.Cart.dialogTimeout,
      cartCount: (state) => state.Cart.inCart.length,
      liveSource: (state) => state.Live.liveSource,
      isLogin: (state) => !!state.Auth.fbAuth.authResponse.accessToken,
      exchangeRate: (state) => state.Currency.exchangeRate,
      currency: (state) => state.Currency.current,
      accessToken: (state) => state.Auth.userDetail.access_token,
      profile: (state) => state.Auth.userDetail,
      isGuest: (state) => state.Auth.userDetail.is_guest,
      cartDetail: (state) => state.Cart.cartDetail,
      deliveryCost: (state) => state.Address.shippingCost
    }),
    desktopSize: function () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    desktopSize: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.sidebar = false
      }
    }
  },
  methods: {
    setCurrency(item) {
      this.selectedCurrency = item.currency
      this.$i18n.locale = item.currency
      this.$store.commit('Currency/setCurrency', item)
      this.$store.dispatch('Payment/onGetBankList').then(() => {
        if (item.currency === 'USD') {
          this.selectedBank = this.bankHost.find((x) => x.currency === 'THB')
        } else {
          this.selectedBank = this.bankHost.find(
            (x) => x.currency === item.currency
          )
        }
        let total =
          parseFloat(
            this.specificExchangeRate(
              this.cartDetail.total_price,
              item.currency
            )
          ) +
          parseFloat(
            this.specificExchangeRate(this.deliveryCost, item.currency)
          )

        this.selectedBank.price = total
        this.$store.commit('Payment/setTransfer', this.selectedBank)
        if (this.$route.name !== 'Checkout') this.$router.go()
      })
    },
    specificExchangeRate(thb_price, currency) {
      if (currency === 'USD') currency = 'THB'

      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    async routeTo(name, requireAuth = false) {
      if (!this.accessToken && requireAuth) {
        this.$store.commit('Auth/loginDialogHandle', true)
        return
      }
      if (this.$route.name !== name) {
        if (name === 'Cart') {
          await this.$store.dispatch('Cart/getCart', this.accessToken)
          // await this.$store.dispatch('Cart/getCart', this.accessToken)
        }
        this.$router.push({ name: name })
      } else if (name === 'Home') {
        this.$router.push({ name: name })
      }
    },
    login(requireLogin) {
      if (!this.accessToken || requireLogin) {
        this.$store.commit('Auth/loginDialogHandle', true)
        return
      }
    },
    openLink(link) {
      if (link) {
        window.open(link)
      }
    },
    async onLogout() {
      this.isLoading = true
      this.$store.dispatch('Auth/logOut')
      await this.$store.commit('Cart/setInCart', null)
      await this.$store.dispatch('Cart/dismissDialog')
      window.location = '/'
    },
    async increaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/add', item)
      this.isLoading = false
    },
    async decreaseItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/decreaseOne', item)
      this.isLoading = false
    },
    async removeItem(product_stock_id) {
      let item = {
        access_token: this.accessToken,
        product_stock_id: product_stock_id
      }
      this.isLoading = true
      await this.$store.dispatch('Cart/removeItem', item)
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
hr.red-divide {
  border: 6px solid var(--cust-red);
  width: 100%;
}
hr.black-divide {
  margin-bottom: 5px;
  border: 3px solid #555555;
}
.header-text {
  color: #756a63;
}
.header-active {
  border-bottom: solid 2px var(--bkk-theme);
}
.sidedock {
  position: fixed;
  z-index: 10;
  display: grid;
  top: calc(50% - 54px);
  left: 8px;
  background: #fff;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
}
.header-tab {
  border-top: 10px solid var(--cust-red) !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20;
  background: #fff;
}
.fadeInOut-enter-active,
.fadeInOut-leave-active {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.fadeInOut-enter,
.fadeInOut-leave-to {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.active-node {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
  background: var(--bkk-grey);
}
</style>
