import { api_domain } from '../../config'
import axios from 'axios'

const banner ={
    get(){
        return axios({
            url: `${api_domain}api/banner`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-banner\n', e.message)
            return { status: e.response.status }           
        })
    },
    getYoutubeLink(){
        return axios({
            url: `${api_domain}api/youtube-link`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-youtube-link\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default banner