<template>
  <v-dialog persistent v-model="loading" width="200px" class="rad-15">
    <v-card elevation="0" color="#fff" class="pa-3 rad-15" height="200px">
      <loading
          :active.sync="loading"
          :is-full-page="false"
          loader="dots"
      ></loading>
    </v-card>
  </v-dialog>
</template>

<script>
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Loading',
  components: {
    loading
  },
  props: {
    loading: {
      default: false
    }
  }
}
</script>

<style scoped>
.rad-15 {
  border-radius: 15px !important;
}
</style>
