import { api_domain } from '../../config'
import axios from 'axios'

const part = {
    getListPart(param){
        return axios({
            url: api_domain + `api/group-part?id=${param.id}`,
            method:'GET'
        }).catch((e)=>{
            console.log('Catch error on getListPart\n', e.message)
            return { status: e.response.status }
        })
    },
    getListGroupPart(param){
        return axios({
            url: api_domain + `api/get-groupPart-by-motor`,
            params:{ motor: param.motor},
            method:'GET'
        }).catch((e)=>{
            console.log('Catch error on getListPart\n', e.message)
            return { status: e.response.status }
        })
    },
    getGroupPart(param){
        return axios({
            url: api_domain + `api/group-part`,
            params:{ id: param.id},
            method:'GET'
        }).catch((e)=>{
            console.log('Catch error on getGroupPart\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default part