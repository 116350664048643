<template>
  <div class="min-screen-support">
    <div id="app">
      <v-app>
        <Header></Header>
        <div style="height: 120px"></div>
        <div class="main-layout">
          <router-view :key="$route.fullPath" />
        </div>
        <LoginDialog :value="loginDialog"></LoginDialog>
        <Footer></Footer>
      </v-app>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import { mapState } from 'vuex'
import { initFbSdk } from '@/plugins/facebook-sdk'
import { app_id } from '@/config'
import LoginDialog from '@/components/LoginDialog'

export default {
  components: {
    Header,
    LoginDialog,
    Footer
  },
  metaInfo: {
    title: 'Thai Bike Part',
    titleTemplate: 'Part And Accessory For Your Bike |%s '
  },
  computed: {
    ...mapState({
      isLogin: (state) => !!state.Auth.fbAuth.authResponse.accessToken,
      loginDialog: (state) => state.Auth.loginDialog
    })
  },
  created() {
    initFbSdk(app_id)
    this.$store.dispatch('Currency/getExchangeRate')
    this.$store.dispatch('Brand/getBrandList')
    this.$store.dispatch('Payment/onGetVAT')
    this.$store.dispatch('CC/getCCList')
  }
}
</script>

<style>
#app {
  font-family: Kanit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}
.min-screen-support {
  position: relative;
  overflow: auto;
}
</style>
