import Service from '../../services'

const state = {
    provinces: [],
    districts: [],
    provincesKR: [],
    districtsKR: [],
    subDistricts: [],
}

const mutations = {
    setProvinces(state, data) {
        state.provinces = data
    },
    setDistricts(state, data) {
        state.districts = data
    },
    setProvincesKR(state, data) {
        state.provincesKR = data
    },
    setDistrictsKR(state, data) {
        state.districtsKR = data
    },
    setSubDistricts(state, data) {
        state.subDistricts = data
    }
}

const getters = {

}

const actions = {
    onGetProvince({ commit }) {
        return Service.masterData.getProvince().then(res => {
            if (res.status === 200) {
                commit('setProvinces', res.data)
            }
        })
    },
    onGetDistrict({ commit }, districtId) {
        return Service.masterData.getDistrict(districtId).then(res => {
            if (res.status === 200) {
                commit('setDistricts', res.data)
            }
        })
    },
    onGetProvinceKR({ commit }) {
        return Service.masterData.getProvinceKR().then(res => {
            if (res.status === 200) {
                commit('setProvincesKR', res.data)
            }
        })
    },
    onGetDistrictKR({ commit }, districtId) {
        return Service.masterData.getDistrictKR(districtId).then(res => {
            if (res.status === 200) {
                commit('setDistrictsKR', res.data)
            }
        })
    },
    onGetSubDistrict({ commit }, subDistrictId) {
        return Service.masterData.getSubDistrict(subDistrictId).then(res => {
            if (res.status === 200) {
                commit('setSubDistricts', res.data)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
