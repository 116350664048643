<template>
  <div>
    <v-row class="form align-center">
      <v-col md="3" cols="12">
        <div>
          <v-autocomplete
            placeholder="Select Brand"
            :items="brandList"
            :search-input.sync="brandName"
            item-text="name"
            item-value="id"
            v-model="brand_id"
            dense
            clearable
            solo
            filled
            class="mt-5 border-none"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <div>
          <v-autocomplete
            placeholder="Select Power"
            :search-input.sync="ccName"
            :items="ccList"
            item-text="name"
            item-value="id"
            v-model="cc_id"
            dense
            clearable
            filled
            solo
            class="mt-5"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <div>
          <v-autocomplete
            placeholder="Select Year"
            :items="yearList"
            dense
            v-model="year"
            clearable
            filled
            solo
            class="mt-5"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <div>
          <v-btn @click="submit" class="cust-btn-color rounded-0" large block>
            SEARCH
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'FilterPanel',
  data() {
    return {
      brand_id: '',
      cc_id: '',
      year: '',
      brandName: '',
      ccName: ''
    }
  },
  props: {
    brand: {
      default: null
    },
    cc: {
      default: null
    },
    selectYear: {
      default: null
    }
  },
  computed: {
    ...mapState({
      brandList: (state) => state.MainModel.brandList,
      ccList: (state) => state.MainModel.ccList,
      yearList: (state) => state.MainModel.yearList
    })
  },
  methods: {
    submit() {
      if (this.brand_id || this.cc_id || this.year) {
        this.$router.push({
          name: 'Home',
          query: { brand: this.brandName, cc: this.ccName, year: this.year }
        })
      } else {
        this.$router.push({
          name: 'Home'
        })
      }
    }
  },
  async created() {
    this.$store.dispatch('Brand/getBrandList')
    this.$store.dispatch('CC/getCCList')
    this.brand_id = this.brand ? this.brand : null
    this.cc_id = this.cc ? this.cc : null
    this.year = this.selectYear ? this.selectYear : null
    await this.$store.dispatch('MainModel/onGetFilterProp', {
      brand: this.brand_id,
      cc: this.cc_id,
      year: this.year
    })
  }
}
</script>

<style>
</style>