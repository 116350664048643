import Service from '../../services'

const state = {
    brandList: null
}

const getters = {}
const actions = {
    getBrandList({ commit }) {
        return Service.brand.get().then((res) => {
            if (res.status === 200) {
                commit('setBrandList', res.data)
            }
        })
    }
}
const mutations = {
    setBrandList(state, data) {
        data.forEach(x => {
            x.image = x.image ? JSON.parse(x.image) : x.image;
        });
        state.brandList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


