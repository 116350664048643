import Service from '../../services'
const state = {
    listParts: [],
    listGroupParts: [],
    currentGroupPart: []
}
const actions = {
    onGetListPart({ commit }, param) {
        return Service.part.getListPart(param).then((res) => {
            if (res.status === 200) {
                commit('setlistParts', res.data)
                return true
            }
        })
    },
    ongetListGroupPart({ commit }, param) {
        return Service.part.getListGroupPart(param).then((res) => {
            if (res.status === 200) {
                commit('setlistGroupParts', res.data)
                return true
            }
        })
    },
    ongetGroupPart({ commit }, param) {
        return Service.part.getGroupPart(param).then((res) => {
            if (res.status === 200) {
                commit('setGroupPart', res.data)
                return true
            }
        })
    }
}
const mutations = {
    setlistParts(state, data) {
        state.listParts = data;
    },
    setlistGroupParts(state, data) {
        state.listGroupParts = data;
    },
    setGroupPart(state, data) {
        if (data.image && data.parts) {
            data.image = JSON.parse(data.image)
            data.parts = JSON.parse(data.parts)
            state.currentGroupPart = data;
        }else{
            state.currentGroupPart = {
                name:'',
                image:[],
                parts:[]
            }
        }
    }
}
const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}