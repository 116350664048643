<template>
  <div class="fill-height py-5">
    <Loading :loading="isLoading"></Loading>
    <div class="elevation-2 pt-5" style="width: 100%">
      <v-breadcrumbs class="cust-color" large :items="items">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-row>
      <v-col class="col-lg-3 col-sm-6">
        <v-img
          class="align-center"
          :src="require('../assets/img/titlebar.png')"
        >
          <div
            class="cust-color text-left"
            style="position: absolute; top: 15px; left: 180px"
          >
            <h3>{{ currentModel.name }} ({{ currentModel.year }})</h3>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <div class="row justify-center">
      <div class="col-lg-4 col-md-12">
        <div>
          <v-img class="mx-auto" :src="currentModel.image[0]" contain> </v-img>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <v-row>
          <v-col v-for="item in part_type" :key="item.id" cols="12">
            <GroupPartCard
              @cardClick="cardClick(item.id)"
              :model="{ name: item.name, image: brandImage }"
            ></GroupPartCard>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { part_type } from '../config'
import Loading from '../components/Loading'
import GroupPartCard from '../components/GroupPartCard'
import { mapState } from 'vuex'
export default {
  name: 'MainPart',
  components: {
    Loading,
    GroupPartCard
  },
  data() {
    return {
      isLoading: false,
      items: [
        {
          text: this.$route.query.brand,
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'Home',
            query: {
              brand: this.$route.query.brand
            }
          }
        },
        {
          text: this.$route.query.model,
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'MainProduct',
            query: {
              brand: this.$route.query.brand,
              cc: this.$route.query.cc,
              year: this.$route.query.year,
              model: this.$route.query.model
            }
          }
        }
      ],
      brandImage: '',
      part_type: part_type
    }
  },
  computed: {
    ...mapState({
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList,
      currentModel: (state) => state.MainModel.currentModel
    })
  },
  methods: {
    async queryRoute() {
      if (this.$route.query) {
        await this.$store.dispatch('MainModel/onGetSpecificModel', {
          brand: this.nameToId(this.$route.query.brand, this.brandList).id,
          cc: this.nameToId(this.$route.query.cc, this.ccList).id,
          year: this.$route.query.year,
          model: this.$route.query.model
        })
      }
    },
    nameToId(name, list) {
      return list ? list.find((x) => x.name === name) : ''
    },
    cardClick(id) {
      this.$router.push({
        name: 'PartCatalogue',
        params: {
          brand: this.$route.query.brand,
          cc: this.$route.query.cc,
          year: this.$route.query.year,
          model: this.$route.query.model,
          type: id
        }
      })
    }
  },
  created() {
    this.isLoading = true
    this.queryRoute()
    this.brandImage = this.nameToId(
      this.$route.query.brand,
      this.brandList
    ).image
    this.isLoading = false
  }
}
</script>

<style>
</style>