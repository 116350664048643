import Service from '../../services'

const state = {
    bank:null,
    bankHost:null,
    transfer:{},
    vat:null,
}

const getters = {

}

const actions = {
    onGetVAT({ commit }){
        return Service.payment.getVat().then(res => {
            if(res.status === 200){
                commit('setVAT',res.data)
                return true
            }
        })
    },
    onGetBankList({ commit }){
        return Service.payment.getBankList().then(res => {
            if(res.status === 200){
                commit('setBankList',res.data)
                return true
            }
        })
    },
    onHandlePayment ({ commit }, data) {
        return Service.payment.handlePayment(data).then(res => {
            return res
        })
    }
}

const mutations = {
    setBankList(state,data){
        state.bank = data.from;
        state.bankHost = data.to;
    },
    setVAT(state,data){
        state.vat = data;
    },
    setTransfer(state, data){
        state.transfer = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
