import Service from '../../services'

const state ={
    ccList:null
}

const getters={}
const actions={
    getCCList({commit}){
        return Service.cc.get().then((res)=>{
            if(res.status === 200){
                commit('setCCList', res.data)
            }
        })
    }
}
const mutations={
    setCCList(state,data){
        data.forEach(x => {
          x.image = x.image? JSON.parse(x.image):x.image;
        });
        state.ccList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}