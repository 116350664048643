import { api_domain } from '../../config'
import axios from 'axios'

const categories = {
  getAllCategories(token) {
    return axios({
      url: api_domain + 'api/get-categories',
      headers:{accesstoken : token},
      method: 'GET'
    }).catch((e) => {
      console.log('Catch error on getAllCategories\n', e.message)
      return {
        status: e.response.status
      }
    })
  }
}

export default categories
