import { api_domain } from '../../config'
import axios from 'axios'

const live = {
    createLive(access_token, payload) {
        // return axios({
        //     url: api_domain + '',
        //     data: { access_token: access_token, data: payload },
        //     method: 'POST'
        // }).catch((e) => {
        //     console.log('Catch error on createLive\n', e.message)
        //     return { status: e.response.status }
        // })
    },
    showProduct(access_token, sku) {
        // return axios({
        //     url: api_domain + '',
        //     data: { access_token: access_token, sku: sku },
        //     method: 'POST'
        // }).catch((e)=>{
        //     console.log('Catch error on showProduct\n', e.message)
        //     return { status: e.response.status }
        // })
    },
    getAllProductLive(liveId,access_token) {
        return axios({
            url: api_domain + `api/get-products-facebook-live/${liveId}`,
            headers: { accesstoken: access_token },
            method: 'GET'
        }).catch((e)=>{
            console.log('Catch error on getAllProductLive\n', e.message)
            return { status: e.response.status }
        })
    },
    getAllLiveHistory(access_token) {
        return axios({
            url: api_domain + 'api/facebooklive',
            headers: { accesstoken: access_token },
            method: 'GET'
        }).catch((e)=>{
            console.log('Catch error on getLiveHistory\n', e.message)
            return { status: e.response.status }
        })
    },
    getCurrentLive(access_token) {
        return axios({
            url: api_domain + 'api/is-online',
            data: { access_token: access_token },
            method: 'GET'
        }).catch((e)=>{
            console.log('Catch error on getCurrentLive\n', e.message)
            return { status: e.response.status }
        })
    }
}

export default live