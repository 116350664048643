<template>
  <div style="height" class="pa-5 pa-md-12 footer-bg">
    <v-img
      alt="footer"
      height="100%"
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        filter: brightness(50%);
      "
      src="http://hellocst.com/media/wysiwyg/Lowadvp7_1.jpg"
    >
    </v-img>

    <div>
      <v-row justify="center" align-center>
        <v-btn class="mr-4" style="background: #fbe404" fab>
          <v-img
            alt="kakao icon"
            class="pointer"
            @click="openTab('kakao')"
            height="48"
            contain
            :src="require(`../assets/img/social/kakao.png`)"
          >
          </v-img>
        </v-btn>
        <v-btn class="mr-4" style="background: #ffff" fab>
          <v-img
            alt="gmail icon"
            class="pointer"
            @click="openLink('mailto:hellocst209@gmail.com')"
            height="50"
            contain
            :src="require(`../assets/img/social/gmail.png`)"
          >
          </v-img>
        </v-btn>
        <v-btn class="mr-4" style="background: #3b5998" fab>
          <v-img
            class="pointer"
            alt="facebook icon"
            @click="openLink('https://www.facebook.com/Hellocst')"
            height="50"
            contain
            :src="require(`../assets/img/social/fb.png`)"
          >
          </v-img>
        </v-btn>
        <v-btn class="mr-4" style="background: #c13584" fab>
          <v-img
            alt="instagram icon"
            class="pointer"
            @click="openLink('https://www.instagram.com/09somchai/')"
            height="50"
            contain
            :src="require(`../assets/img/social/ig.png`)"
          >
          </v-img>
        </v-btn>
        <v-btn class="mr-4" style="background: #04c404" fab>
          <v-img
            alt="line icon"
            class="pointer"
            @click="openLink('http://line.me/ti/p/~hellocst')"
            height="50"
            :src="require(`../assets/img/social/line.png`)"
          >
          </v-img>
        </v-btn>
        <v-btn class="mr-4" style="" fab>
          <v-img
            alt="line icon"
            class="pointer"
            @click="openTab('what app')"
            height="100%"
            width="65px"
            :src="require(`../assets/img/social/what_app.png`)"
          >
          </v-img>
        </v-btn>
      </v-row>
      <v-row
        style="position: relative"
        class="my-3"
        justify="center"
        align-center
      >
        <v-btn
          @click="$router.push({ name: 'PrivacyPolicy' })"
          class="mx-3"
          dark
          text
          ><h2>Privacy Policy</h2>
        </v-btn>
        <v-btn
          @click="$router.push({ name: 'ContactUs' })"
          class="mx-3"
          dark
          text
          ><h2>Contact Us</h2>
        </v-btn>
      </v-row>
      <v-row justify="center">
        <span style="position: relative" class="text-center white--text">
          Hello Custom 258/6 Soi 12 Praram 3 Bang Khlo, Bang Kho Laem, Bangkok
          10120
        </span>
      </v-row>
      <v-row
        style="position: relative"
        class="pb-10 white--text text-center"
        justify="center"
      >
        <div class="mr-2">
          <v-icon class="white--text"> mdi-basket-outline </v-icon> Tel : +6695
          689 4280
        </div>
        <div>
          <v-icon class="white--text"> mdi-email-outline </v-icon> Email :
          hellocst209@gmail.com
        </div>
      </v-row>
    </div>
    <modal name="kakao" :width="300" :height="300">
      <v-img
              alt="whatapp icon"
              contain
              height="100%"
              :src="require(`../assets/img/kakao.jpg`)"
      >
      </v-img>
    </modal>
    <modal name="whatapp" :width="399" :height="710">
      <v-img
              alt="whatapp icon"
              contain
              height="100%"
              :src="require(`../assets/img/what_app.jpg`)"
      >
      </v-img>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      socialIcon: [
        {
          src: require('../assets/img/social/fb.png'),
          link: 'https://www.facebook.com/Hellocst/'
        },
        {
          src: require('../assets/img/social/ig.png'),
          link: ''
        },
        {
          src: require('../assets/img/social/line.png'),
          link: 'https://www.instagram.com/mybkkshop/'
        }
      ]
    }
  },
  methods: {
    openLink(link) {
      if (link) {
        window.open(link)
      }
    },
    openTab(app){
      if (app == 'kakao'){
        this.$modal.show('kakao')
      }else if(app == 'what app'){
        this.$modal.show('whatapp')
      }
    }
  }
}
</script>

<style scoped>
.footer-bg {
  position: relative;
  background: transparent;
  overflow: hidden;
}
.border-line {
  position: relative;
  border-bottom: solid 2px var(--bkk-darkblue);
  width: 80px;
}
</style>
