<template>
  <v-col class="col-12">
    <div v-for="(item, index) in brandModel" :key="index" class="mb-5">
      <div v-if="item.motor_model.length > 0" class="ml-2">
        <v-row>
          <v-col class="col-lg-2 col-sm-6">
            <v-img :src="require('../assets/img/titlebar.png')">
              <div class="cust-color ml-5 text-center">
                <h2>{{ item.name }}</h2>
              </div>
            </v-img>
          </v-col>
          <v-col class="col-12"> </v-col>
          <v-col class="col-1"> </v-col>
            <v-col
            
              class="col-lg-2 col-md-4 col-sm-6 col-12"
              v-for="row in item.motor_model"
              :key="row.id"
            >
              <ProductItem :model="row" @onClick="bannerClick"></ProductItem>
            </v-col>
        </v-row>
      </div>
    </div>
  </v-col>
</template>

<script>
import ProductItem from '../components/ProductItem'
export default {
  name: 'MainProduct',
  components: {
    ProductItem
  },
  props: {
    brandModel: {
      type: Array
    }
  },
  methods: {
    bannerClick($event) {
      this.$emit('bannerClick', $event)
    }
  }
}
</script>

<style>
</style>